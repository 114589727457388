








import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({})
export default class BadgeWrapper extends Vue {
  @Prop(Number) private amount!: number;
  @Prop({type: String, default: 'span'}) private tag!: number;
  @Prop({
    type: String, default: 'top-left', validator(value: any): boolean {
      return ['top-left', 'top-right', 'bottom-left', 'bottom-right'].indexOf(value) >= 0;
    },
  }) private position!: string;
}
