import {Inspection_inspection_parameterValues} from '@/types/intrador';
import {InspectionFormatterGroup} from '@/plugins/inspection/InspectionFormatterGroup';

/**
 * Inspection Parameter Group
 */
export class InspectionFormatterParameterGroup extends InspectionFormatterGroup {
  public values: Inspection_inspection_parameterValues[] = [];
  public viewType: string = 'list';

  constructor(name: string, viewType: string) {
    super(name);
    this.viewType = viewType;
  }

  get isValid() {
    return this.values.length > 0;
  }
}
