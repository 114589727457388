import {
  Inspection_inspection_parameterValues_images,
  Inspection_inspection_parameterValues_remarks,
} from '@/types/intrador';
import {InspectionFormatterGroup} from '@/plugins/inspection/InspectionFormatterGroup';

/**
 * Inspection Image
 */
export interface InspectionFormatterImage extends Inspection_inspection_parameterValues_images {
  name: string|null;
  filename: string|null;
  recentlyCreated: boolean;
  deletedAt: Date | null;
  remark: Inspection_inspection_parameterValues_remarks | null;
}

/**
 * Inspection Image Group
 */
export class InspectionFormatterImageGroup extends InspectionFormatterGroup {
  public banner: boolean = false;
  public images: InspectionFormatterImage[] = [];

  get isValid() {
    return this.images.length > 0;
  }
}
