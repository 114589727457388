































import {Component, Emit, Mixins, Model, Prop, Vue, Watch} from 'vue-property-decorator';
import ComboSelect from '@/layouts/back-office/elements/input/InputComboSelect.vue';
import InputMixin from '@/layouts/back-office/elements/input/InputMixin';

@Component({
  components: {ComboSelect},
})
export default class InputAssetGroup extends Mixins(InputMixin) {
  protected focus: boolean = false;

  @Prop(String) private text!: string;
  @Prop(Boolean) private withFlow?: boolean;

  public setFocus(focus: boolean) {
    this.focus = focus;
  }

  private get variables() {
    return {
      withFlow: this.withFlow,
    };
  }

  private onItemSelected(item: any) {
    this.$emit('itemSelected', item);
  }

  @Watch('focus')
  private onFocusChanged() {
    if (this.focus) {
      this.$emit('focus');
    } else {
      this.$emit('blur');
    }
  }

  @Emit('textChange')
  private onTextChange(value: string) {
    return value;
  }
}
