import {
  Inspection_inspection, Inspection_inspection_images,
  Inspection_inspection_flow_parameterCategories_parameterGroups_parameters,
  Inspection_inspection_parameterValues,
  Inspection_inspection_parameterValues_damages,
  InspectionOld_inspection,
  InspectionOld_inspection_asset_images,
} from '@/types/intrador';
import { InspectionFormatterImageGroup } from '@/plugins/inspection/InspectionFormatterImageGroup';
import { InspectionFormatterParameterGroup } from '@/plugins/inspection/InspectionFormatterParameterGroup';

/**
 * Extends the parameterValue to house the flow parameters
 */
export interface InspectionFormatterValueExtended extends Inspection_inspection_parameterValues {
  flowParameter?: Inspection_inspection_flow_parameterCategories_parameterGroups_parameters;
  placeholder?: boolean;
  value: any;
}

/**
 * Flattened list of inspection parameterValues
 */
export interface InspectionFormatterValues {
  [key: string]: InspectionFormatterValueExtended;
}

/**
 * Extends the damage with a parameter definition
 */
export interface InspectionFormatterDamages extends Inspection_inspection_parameterValues_damages {
  flowParameter: Inspection_inspection_flow_parameterCategories_parameterGroups_parameters;
}

/**
 * Formatter class, calculates optimal render values
 */
export default class InspectionFormatter {
  public images: InspectionFormatterImageGroup[] = [];
  public parameters: InspectionFormatterParameterGroup[] = [];

  public inspectionData: Inspection_inspection | InspectionOld_inspection | null = null;
  public inspectionValues: InspectionFormatterValues = {};
  public inspectionImages: Inspection_inspection_images[] = [];
  public inspectionDamages: InspectionFormatterDamages[] = [];
  protected inspectionImageIds: Array<string | null> = [];

  constructor(inspection?: Inspection_inspection) {
    if (inspection) {
      this.inspectionData = inspection;
    }
  }

  /**
   * Get the inspection
   */
  get inspection(): Inspection_inspection | null {
    return this.inspectionData as Inspection_inspection;
  }

  /**
   * Set the inspection and automatically format when changes are detected
   *
   * @param inspection
   */
  set inspection(inspection: Inspection_inspection | null) {
    // Only update if the data has changed
    if (inspection !== this.inspectionData) {
      this.inspectionData = inspection;
      this.format();
    }
  }

  /**
   * Format the inspection so it becomes way easier to render
   */
  public format() {
    // Check if the inspection data exists
    if (!this.inspectionData) {
      return;
    }

    this.inspectionValues = {};
    this.inspectionDamages = [];
    this.inspectionImages = [];
    this.inspectionImageIds = ((this.inspectionData as Inspection_inspection).images
      || (this.inspectionData as InspectionOld_inspection).asset?.images || []).map((i) => i!.id);

    const parameters: InspectionFormatterParameterGroup[] = [];
    const images: InspectionFormatterImageGroup[] = [];

    const parameterValues: InspectionFormatterValues = {};

    // Calculate flat map of values, this prevents a n*n scenario
    if (this.inspectionData.parameterValues) {
      (this.inspectionData as Inspection_inspection).parameterValues?.forEach((pv) => {
        if (pv && pv.parameter) {
          parameterValues[`${pv.parameterGroup ? pv.parameterGroup.id : '0'}-${pv.parameter.id}`] = pv;
        }
      });
    }

    if (this.inspectionData.flow && this.inspectionData.flow.parameterCategories) {
      this.inspectionData.flow.parameterCategories.forEach((parameterCategory) => {
        if (parameterCategory && parameterCategory.parameterGroups) {
          parameterCategory.parameterGroups.forEach((parameterGroup) => {
            if (parameterGroup && parameterGroup.parameters) {
              const name = parameterCategory.name + (parameterGroup.name ? ` - ${parameterGroup.name}` : '');
              const paramGroup = new InspectionFormatterParameterGroup(name, parameterGroup.viewType!);
              const imageGroup = new InspectionFormatterImageGroup(name);

              parameterGroup.parameters.forEach((parameter) => {
                if (parameter) {
                  const key = `${parameterCategory.parameterCategoryPcid}-${parameterGroup.parameterGroupPgid || 0}-${parameter.parameterPid}`;
                  const parameterKey = `${parameterGroup.parameterGroupPgid || 0}-${parameter.parameterPid}`;

                  if (parameterValues[parameterKey]) {
                    this.inspectionValues[key] = parameterValues[parameterKey];
                    this.inspectionValues[key].flowParameter = parameter;

                    delete parameterValues[parameterKey];

                    this.addParameter(paramGroup, this.inspectionValues[key]);
                    this.addImages(imageGroup, this.inspectionValues[key]);

                    // Add damages to global array
                    (this.inspectionValues[key].damages || []).forEach((d) => {
                      if (d) {
                        this.inspectionDamages.push({
                          flowParameter: parameter,
                          ...d,
                        });
                      }
                    });
                  } else {
                    // Add this when edit mode is enabled
                    this.inspectionValues[key] = {
                      id: '',
                      placeholder: true,
                      value: null,
                      images: [],
                      damages: [],
                      remarks: [],
                      address: null,
                      declined: false,
                      parameter: {
                        id: parameter.parameterPid!,
                        name: parameter.name,
                        type: parameter.type,
                        metric: parameter.metric,
                        parameterOptions: [],
                        __typename: 'Parameter',
                      },
                      parameterGroup: {
                        id: parameterGroup.parameterGroupPgid!,
                        __typename: 'ParameterGroup',
                      },
                      flowParameter: parameter,
                      createdAt: (new Date()).toUTCString(),
                      __typename: 'InspectionParameterValue',
                    };
                  }
                }
              });

              if (parameterCategory.id === '4') {
                if ('0-893' in parameterValues) {
                  paramGroup.values.unshift(parameterValues['0-893']);
                }
              }

              if (paramGroup.isValid) {
                parameters.push(paramGroup);
              }

              if (imageGroup.isValid) {
                images.push(imageGroup);
              }
            }
          });
        }
      });
    }

    const otherParameters = new InspectionFormatterParameterGroup('', 'list');
    const otherImages = new InspectionFormatterImageGroup('');

    for (const pv in parameterValues) {
      if (!parameterValues[pv] || parameterValues[pv].parameter === null) {
        continue;
      }

      const p = parameterValues[pv].parameter;

      parameterValues[pv].flowParameter = {
        id: '',
        name: '',
        type: 'string',
        parameterPid: p?.id || '',
        options: p?.parameterOptions || [],
        ...p,
        // description: null,
        // required: false,
        // min: null,
        // max: null,
        metric: null,
        __typename: 'FlowParameter',
      };

      this.addParameter(otherParameters, parameterValues[pv]);
      this.addImages(otherImages, parameterValues[pv]);
    }

    if (otherParameters.values.length > 0) {
      parameters.push(otherParameters);
    }

    const inspectionImages = (this.inspectionData as Inspection_inspection).images
      || (this.inspectionData as InspectionOld_inspection).asset?.images || [];

    this.inspectionImageIds.forEach((id, index) => {
      if (id && index < inspectionImages.length) {
        const image = inspectionImages[index]!;

        const d = {
          name: image.id || '',

          recentlyCreated: false,
          deletedAt: null,
          checks: null,
          remark: null,

          ...image,
        };
        otherImages.images.push(d);
        this.inspectionImages.push(d);
      }
    });

    if (otherImages.images.length > 0) {
      images.push(otherImages);
    }

    this.parameters = parameters;
    this.images = images;
  }

  /**
   * Check if the parameter is filled
   *
   * @param value
   * @return boolean
   */
  protected parameterFilled(value: InspectionFormatterValueExtended): boolean {
    const hasValue = (value.value !== null && value.value.toString().length > 0);
    const hasImages = (value.images !== null && value.images.length > 0);
    const hasRemarks = (value.remarks !== null && value.remarks.length > 0);
    const hasDamages = (value.damages !== null && value.damages.length > 0);

    return hasValue || hasImages || hasRemarks || hasDamages;
  }

  /**
   * Add a parameter to the formatted values
   *
   * @param group
   * @param value
   */
  protected addParameter(group: InspectionFormatterParameterGroup, value: InspectionFormatterValueExtended) {
    switch (value.flowParameter!.type) {
      case 'image':
      case 'video':
        // Do not add images because they are shown in the images list
        break;
      case 'string':
      case 'integer':
      case 'float':
      case 'boolean':
      case 'date':
      case 'address':
        group.values.push(value);
        break;
      default:
        const option = value.flowParameter!.options!.find((po) => po!.id === value.value);
        group.values.push({
          ...value,
          value: option ? option.value : value.value,
        });
        break;
    }
  }

  /**
   * Add an image to the formatted values
   *
   * @param group
   * @param value
   */
  protected addImages(group: InspectionFormatterImageGroup, value: InspectionFormatterValueExtended) {
    // If left front
    if (value.parameter!.id === '538') {
      group.banner = true;
    }

    // @ts-ignore
    value.images = (value.images || []).map((i) => {
      if (i === null) {
        return null;
      }

      const index = this.inspectionImageIds.indexOf(i.id);
      if (index > -1) {
        this.inspectionImageIds[index] = null;

        let im: Inspection_inspection_images | InspectionOld_inspection_asset_images | null = null;
        if ((this.inspectionData as InspectionOld_inspection).asset?.images) {
          im = (this.inspectionData as InspectionOld_inspection).asset!.images![index];
        } else {
          im = (this.inspectionData as Inspection_inspection).images![index];
        }

        const image = {
          name: value.flowParameter!.name,
          parameterId: value.parameter!.id,
          declined: value.declined,
          recentlyCreated: false,
          deletedAt: null,
          filename: null,
          remark: (value.remarks || []).length > 0 ? value.remarks![0] : null,

          ...(im || {}),
          ...i,
        };

        group.images.push(image);

        return image;
      }
    }).filter((image) => image);
  }
}
