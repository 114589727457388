


















import {Component, Vue} from 'vue-property-decorator';
import {InspectionFormatterValueExtended} from '@/plugins/inspection/InspectionFormatter';
import InspectionInputImage from './InspectionInputImage.vue';
import Modal from '@/layouts/back-office/elements/modals/Modal.vue';

@Component({
  components: {Modal, InspectionInputImage},
})
export default class InspectionModalImages extends Vue {
  private inspectionId: string|null = null;
  private value: InspectionFormatterValueExtended|null = null;

  public open(inspectionId: string, value: InspectionFormatterValueExtended) {
    this.inspectionId = inspectionId;
    this.value = value;

    (this.$refs.modal as Modal).open();
  }

  public close() {
    (this.$refs.modal as Modal).close();
  }

  private opid(opid: string) {
    // Add the created opid to the values, so we save items to the
    if (!this.value!.id) {
      this.value!.id = opid;
      this.value!.placeholder = false;
    }
  }

  private uploadImage(image: any) {
    this.$emit('upload-image', image);
  }

  private openImage(image: any) {
    this.$emit('open-image', image.id);
  }
}
