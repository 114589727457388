




















import {Component, Vue, Watch} from 'vue-property-decorator';
import {InspectionFormatterValueExtended} from '@/plugins/inspection/InspectionFormatter';
import Modal from '@/layouts/back-office/elements/modals/Modal.vue';

@Component({
  components: {Modal},
})
export default class InspectionModalRemarks extends Vue {
  private value: InspectionFormatterValueExtended|null = null;

  private message: string = '';

  private requireInput: boolean = false;

  private get disabled() {
    return this.requireInput && (!this.message || this.message.length < 2);
  }

  public open(value: InspectionFormatterValueExtended, requireInput: boolean = false) {
    this.requireInput = requireInput;
    this.value = value;

    if (this.value && this.value.remarks && this.value.remarks.length > 0 && this.value.remarks[0]) {
      this.message = this.value.remarks[0].message || '';
    } else {
      this.message = '';
    }

    (this.$refs.modal as Modal).open();

    this.$nextTick(() => {
      (this.$refs.textarea as HTMLElement).focus();
    });
  }

  public update() {
    if (this.value) {
      if (this.message && this.message.length > 0) {
        if (!this.value.remarks || this.value.remarks.length < 1) {
          this.value.remarks = [{ id: '', message: '', __typename: 'InspectionParameterValueRemark' }];
        }

        if (this.value.remarks[0]) {
          this.value.remarks[0].message = this.message;
        }

        // Change the placeholder, we want to save the value
        this.value.placeholder = false;
      } else {
        this.value.remarks = [];
      }
    }

    if (this.value && this.value.remarks) {
      this.value.remarks = this.value.remarks.filter((r) => r && r.message && r.message.length > 0);
    }
  }

  public close() {
    (this.$refs.modal as Modal).close();
  }
}
