
















































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import Dropzone from 'vue2-dropzone';
import {captureException} from '@sentry/browser';
import Spinner from '@/layouts/back-office/elements/Spinner.vue';

export interface Upload {
  uuid: string;
  filename: string;
  dataUrl?: string;
  size: string;
  status: string;
  uploaded: boolean;

  file: any;
  upload: any;
  data?: any;
  errors?: string[];
}

@Component({
  components: {Spinner, Dropzone},
})
export default class Uploader extends Vue {
  @Prop(String) protected id!: string;
  @Prop(String) protected url!: string;
  @Prop(Object) protected params!: object;
  @Prop({type: Number, default: 10}) protected maxFileSize!: string;
  @Prop({type: Array, default: () => []}) protected acceptedFiles!: string[];
  @Prop({type: Boolean, default: false}) protected hideUploaderOnDataReceived!: boolean;

  private loading: boolean = false;
  private showLargeFileMessage: boolean = false;
  private uploads: Upload[] = [];
  private errors: any[] = [];

  private paramsChanging: boolean = false;

  get showUploader(): boolean {
    return !this.hideUploaderOnDataReceived || !this.showData;
  }

  get successUploads() {
    return this.uploads.filter((upload) => upload.status === 'success' || (upload.data && upload.data.length > 0));
  }

  get showData() {
    return 'default' in this.$scopedSlots && !this.loading &&
      this.successUploads.length > 0 && this.successUploads.every((upload) => upload.uploaded);
  }

  get options() {
    return {
      url: process.env.VUE_APP_API_DOMAIN + this.url,
      maxFilesize: this.maxFileSize,
      acceptedFiles: this.acceptedFiles.join(','),
      headers: {
        Authorization: this.$store.getters.authorization,
      },
      previewTemplate: '<div></div>',
      params: this.params,
    };
  }

  get readableErrors() {
    return this.errors.map((error) => {
      switch (error) {

        case 'validation.uploaded':
          // tslint:disable-next-line
          return this.$it('import.validation.uploaded', { extensions: this.acceptedFiles.join(', '), size: this.maxFileSize }, 'It appears that the file is invalid, be sure that you uploaded {extensions} with maximum file size of {size}MB');

        default:
          return error;
      }
    });
  }

  public remove(uuid: string) {
    const index = this.uploads.findIndex((upload) => upload.uuid === uuid);
    if (index >= 0) {
      this.uploads.splice(index, 1);
    }
  }

  public clear() {
    this.uploads = [];
    this.errors = [];
  }

  @Watch('loading')
  protected loadingChange(value: boolean) {
    if (!value) {
      this.showLargeFileMessage = false;
    } else {
      setTimeout(() => {
        if (this.loading) {
          this.showLargeFileMessage = true;
        }
      }, 2000);
    }
  }

  /**
   * This is necessary otherwise the params are not correctly updated
   *
   * @protected
   */
  @Watch('params')
  protected paramsChanged() {
    this.paramsChanging = true;
    this.$nextTick(() => {
      this.paramsChanging = false;
    });
  }

  private filesAdded() {
    this.errors = [];
  }

  private fileAdded(file: any) {
    this.loading = true;

    const upload: Upload = {
      uuid: file.upload.uuid,
      filename: file.upload.filename,
      size: file.upload.total,
      status: file.status,
      uploaded: false,

      file,
      upload: file.upload,
    };

    this.uploads.push(upload);
  }

  private thumbnail(file: any, dataUrl: string) {
    const index = this.uploads.findIndex((upload) => upload.uuid === file.upload.uuid);
    if (index >= 0) {
      const upload = this.uploads[index];

      upload.dataUrl = dataUrl;
      upload.status = file.status;

      this.uploads[index] = Object.assign({}, upload);
    }
  }

  private fileUploadSuccess(file: any, response: any) {
    this.loading = false;

    const upload = this.onDataReceived(file, response.data);

    this.$emit('file-uploaded', upload);
  }

  private fileUploadError(file: any, response: any) {
    this.loading = false;

    let errors = [];
    try {
      if (typeof response === 'string') {
        errors = [response];
      } else if ('errors' in response) {
        if (Array.isArray(response.errors)) {
          errors = response.errors;
        } else {
          errors = Object.values(response.errors);
        }
      } else if ('error' in response) {
        errors = [response.error];
      } else if ('exception' in response) {
        errors = [response.message];
      }
    } catch (e) {
      errors = [this.$it('global.error', 'Something went wrong, please try again')];
      captureException(e);
    }

    this.errors = errors;

    if ('data' in response && response.data !== null) {
      this.onDataReceived(file, response.data, errors);
    } else {
      this.onErrorReceived(file, errors);
    }
  }

  private onDataReceived(file: any, data: any, errors: string[] = []) {
    const index = this.uploads.findIndex((upload) => upload.uuid === file.upload.uuid);
    if (index >= 0) {
      const upload = this.uploads[index];

      upload.dataUrl = file.dataURL;
      upload.status = file.status;
      upload.uploaded = true;
      upload.data = data;
      upload.errors = errors;

      this.uploads[index] = Object.assign({}, upload);

      return this.uploads[index];
    }
  }

  private onErrorReceived(file: any, errors: string[]) {
    const index = this.uploads.findIndex((upload) => upload.uuid === file.upload.uuid);
    if (index >= 0) {
      const upload = this.uploads[index];

      upload.dataUrl = file.dataURL;
      upload.status = file.status;
      upload.uploaded = true;
      upload.errors = errors;

      this.uploads[index] = Object.assign({}, upload);

      return this.uploads[index];
    }
  }

  private fileUploadProgress(totalUploadProgress: any, totalBytes: any, totalBytesSent: any) {
    return undefined;
  }
}
