


























import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import Uploader, {Upload} from '@/layouts/back-office/elements/upload/Uploader.vue';
import SpinnerButton from '@/layouts/back-office/elements/SpinnerButton.vue';
import Spinner from '@/layouts/back-office/elements/Spinner.vue';
import UploadThumb from '@/layouts/back-office/elements/upload/UploadThumb.vue';
import {InspectionFormatterImage} from '@/plugins/inspection/InspectionFormatterImageGroup';
import {Inspection_inspection_parameterValues_parameter} from '@/types/intrador';

@Component({
  components: {UploadThumb, Spinner, SpinnerButton, Uploader},
})
export default class InspectionInputImage extends Vue {
    @Prop(Array) protected images!: InspectionFormatterImage[];
    @Prop(String) protected parameterValueId!: string;
    @Prop(Object) protected parameter!: Inspection_inspection_parameterValues_parameter;
    @Prop(String) protected inspectionId!: string;

    protected allImages: InspectionFormatterImage[] = [];

    get availableImages(): InspectionFormatterImage[] {
      return this.allImages.filter((image) => image.deletedAt === null);
    }

    get url() {
      return `/inspection/inspections/${this.inspectionId}/images`;
    }

    get params() {
      if (this.parameterValueId) {
        return {
          object_parameter_opid: this.parameterValueId,
        };
      }

      if (this.parameter.id) {
        return {
          parameter_pid: this.parameter.id,
        };
      }

      return {};
    }

    @Watch('images', {immediate: true})
    protected imagesChanged() {
      this.allImages = this.images;
    }

    private open(image: any) {
      this.$emit('open', image);
    }

    private async remove(image: any, upload: Upload | null = null) {
      if (upload !== null) {
        (this.$refs.uploader as Uploader).remove(upload.uuid);
      }

      image.deletedAt = new Date();
    }

    private uploaded(upload: Upload) {
      const data = upload.data;
      const img = {
        id: data.oiid.toString(),

        name: this.parameter.name,

        filename: data.filename,
        original_url: data.original_url,
        thumb_url: data.thumb_url,

        recentlyCreated: true,
        deletedAt: null,
        checks: null,
        remark: null,
        showRecaptureCheck: false,

        __typename: 'InspectionImage' as 'InspectionImage',
      };

      this.allImages.push(img);

      (this.$refs.uploader as Uploader).remove(upload.uuid);

      this.$emit('upload', img);
      this.$emit('opid', data.object_has_parameter_opid.toString());
    }
}
