
























import { Component, Prop, Mixins } from 'vue-property-decorator';
import InputGroup from '@/layouts/back-office/elements/input/InputGroup.vue';
import InputMixin from '@/layouts/back-office/elements/input/InputMixin';

export interface InputSelectOptions {
  [key: string]: any;
}

@Component
export default class InputSimpleRating extends Mixins(InputMixin) {
  @Prop({ type: Boolean, required: true }) private toggle!: boolean;

  private set(value: string) {
    this.value = this.value === value ? null : value;

    // If imperfections or issues, open remarks
    if (this.value === '437' || this.value === '436') {
      this.$emit('open-remarks');
    }
  }
}
