






































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {InspectionFormatterValueExtended} from '@/plugins/inspection/InspectionFormatter';
import InspectionInputImage from '@/components/inspection/edit/InspectionInputImage.vue';
import InputBoolean from '@/layouts/back-office/elements/input/InputBoolean.vue';
import InputDate from '@/layouts/back-office/elements/input/InputDate.vue';
import InputText from '@/layouts/back-office/elements/input/InputText.vue';
import InputSelect from '@/layouts/back-office/elements/input/InputSelect.vue';
import {InputSelectOptions} from '@/layouts/back-office/elements/input/InputSelectMixin';
import InputSimpleRating from '@/layouts/back-office/elements/input/InputSimpleRating.vue';

@Component({
  components: {InputSimpleRating, InputSelect, InputBoolean, InputText, InputDate, InspectionInputImage},
})
export default class InspectionInput extends Vue {
    @Prop(Object) protected value!: InspectionFormatterValueExtended;
    @Prop(String) protected inspectionId!: string;

    protected parameterValueId: string|null = null;

    @Watch('value.id', {immediate: true})
    protected parameterValueChanged() {
      this.parameterValueId = this.value.id;
    }

    /**
     * Formatted value getter
     *
     * @return any
     */
    get fvalue() {
      switch (this.value.flowParameter!.type) {
        case 'bool':
          if (this.value.value === 'true' || this.value.value === true) { return true; }
          if (this.value.value === 'false' || this.value.value === false) { return false; }
          return null;
      }

      return this.value.value;
    }

    /**
     * Formatted value setter
     *
     * @param value  any  The new value
     */
    set fvalue(value: any) {
      this.value.value = value;
      this.value.placeholder = false;
      this.$emit('changed', this.value);
    }

    get options() {
      const options: InputSelectOptions = {};
      this.value.flowParameter!.options!.forEach((option) => {
        if (option && option.value) {
          options[option.value] = option!.id;
        }
      });
      return options;
    }

    /**
     * Propagate image open to viewer
     *
     * @param image
     */
    private openImage(image: any) {
      this.$emit('open-image', image.id);
    }

    /**
     * Propagate upload image open to viewer
     *
     * @param image
     */
    private uploadImage(image: any) {
      this.$emit('upload-image', image);
    }

    /**
     * Attach received OPID to the value
     * @param opid
     */
    private opid(opid: string) {
      // Add the created opid to the values, so we save items to the
      if (!this.value!.id) {
        this.parameterValueId = opid;

        this.value!.id = opid;
        this.value!.placeholder = false;
      }
    }
}
