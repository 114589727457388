

































































import {Component, Emit, Mixins, Model, Prop, Vue, Watch} from 'vue-property-decorator';
import QueryAutocomplete from '@/layouts/back-office/elements/input/InputAutocomplete.vue';
import InputText from '@/layouts/back-office/elements/input/InputText.vue';
import InputMixin from '@/layouts/back-office/elements/input/InputMixin';

@Component({
  components: {InputText, QueryAutocomplete},
})
export default class InputContact extends Mixins(InputMixin) {
  @Prop({type: Boolean, default: true}) protected searchable!: boolean;
  private errors: any = {};

  private id: string | null = null;

  private firstName: any = null;
  private lastName: any = null;
  private email: any = null;
  private phone: any = null;
  private mobile: any = null;

  get isClearable() {
    return this.clearable && this.isFilled && !this.inputDisabled;
  }

  get inputDisabled() {
    return this.id !== null;
  }

  get inputErrors(): any {
    const errors: any = {};

    if (this.firstName === null) {
      errors.firstName = this.$it('contact.firstName.error.required', 'First name is required') as string;
    }
    if (this.lastName === null) {
      errors.lastName = this.$it('contact.lastName.error.required', 'Last name is required') as string;
    }
    if (!this.isValidEmail(this.email)) {
      // @ts-ignore
      errors.email = this.$it('contact.email.error.invalidformat', 'The email that you have entered is not of a valid email format') as string;
    }

    return errors;
  }

  public check(): boolean {
    if (this.id === null) {
      this.errors = this.inputErrors;
    } else {
      this.errors = {};
    }

    return Object.keys(this.errors).length <= 0;
  }

  protected clear() {
    this.id = null;
    this.firstName = null;
    this.lastName = null;
    this.email = null;
    this.phone = null;
    this.mobile = null;

    this.onItemChange();
  }

  @Watch('model', {immediate: true})
  private modelUpdated(contact: any) {
    if (contact !== null) {
      this.setContact(contact, false);
    }
  }

  private setContact(item: any, changed: boolean = true) {
    this.id = item.id;
    this.firstName = item.firstName;
    this.lastName = item.lastName;
    this.email = item.email;
    this.phone = item.phone;
    this.mobile = item.mobile;

    if (changed) {
      this.onItemChange();
    }
  }

  @Emit('change')
  private onItemChange(field: string | null = null) {
    if (field) {
      this.errors[field] = null;
    }

    return {
      id: this.id,
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      phone: this.phone,
      mobile: this.mobile,
    };
  }

  private isValidEmail(email: string): boolean {
    const emailRegex = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
    return emailRegex.test(email);
  }

}
