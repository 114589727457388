





















import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {
  AllGripResources_allGripResources_predictions,
} from '@/types/intrador';
import Card from '@/layouts/back-office/elements/Card.vue';

@Component({components: {Card}})
export default class GripResult extends Vue {
  @Prop(Array) protected predictionData!: AllGripResources_allGripResources_predictions[];

  private get predictions() {
    if (!this.predictionData) {
      return false;
    }
    return this.predictionData.filter((p: AllGripResources_allGripResources_predictions) =>
      (p.value === '0' || p.value === '1'));
  }

}
