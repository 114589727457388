

































































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import Card from '@/layouts/back-office/elements/Card.vue';
import {
  AllGripResources_allGripResources_predictions,
  Inspection_inspection,
  snapshot_inspection,
} from '@/types/intrador';
import {InspectionFormatterImage} from '@/plugins/inspection/InspectionFormatterImageGroup';
import InspectionFormatter from '@/plugins/inspection/InspectionFormatter';
import GripProxy from '@/plugins/misc/GripProxy';
import GripResult from '@/components/GripResult.vue';

interface ImageViewerImage extends InspectionFormatterImage {
  isVideo: boolean;
  checks: any;
}

@Component({
  components: {GripResult, Card},
})
export default class ImageViewer extends Vue {
  @Prop(Object) protected inspection!: snapshot_inspection | Inspection_inspection;
  @Prop(Object) protected formatter!: InspectionFormatter;
  @Prop(Object) protected gripProxy!: GripProxy;

  private isOpen: boolean = false;
  private index: number = 0;
  private scale: number = 1;
  private originX: number | null = null;
  private originY: number | null = null;
  private translateX: number = 0;
  private translateY: number = 0;
  private rotation: number = 0;
  private keyPressListener: ((event: KeyboardEvent) => void) | null = null;
  private images: ImageViewerImage[] = [];
  private userGaveAnomalyFeedback: boolean = false;
  private error: string | null = null;

  private predictionData: AllGripResources_allGripResources_predictions[] = [];

  /**
   * Selected image
   */
  get image() {
    return this.images[this.index];
  }

  get pictureInPictureAnomalyQuestion() {
    return this.$it('inspection.check.questions.ispictureinpictureanomaly', 'Is this really an anomaly?');
  }

  get style() {
    return 'transform: translate(' + Math.round(this.translateX) + 'px, ' + Math.round(this.translateY) + 'px) ' +
      'scale(' + this.scale + ') rotate(' + this.rotation + 'deg);';
  }

  public open(id?: string) {
    if (id) {
      this.select(this.images.findIndex((i) => i.id === id));
    }
    this.isOpen = true;
    this.getPredictionDataForImage(id);
  }

  public close() {
    this.isOpen = false;
  }

  public addImage(image: any) {
    this.images.push({
      ...image,
      isVideo: image.thumb_url === null,
    });
  }

  public left() {
    if (--this.index < 0) {
      this.index = this.images.length - 1;
    }
  }

  public right() {
    if (++this.index > this.images.length - 1) {
      this.index = 0;
    }
  }

  private keyPress(event: KeyboardEvent) {
    if (event.key === 'ArrowLeft') {
      this.left();
    } else if (event.key === 'ArrowRight') {
      this.right();
    } else if (event.key === 'Escape') {
      this.close();
    }
  }

  private created() {
    this.keyPressListener = this.keyPress.bind(this);
    document.addEventListener('keydown', this.keyPressListener);
  }

  private mounted() {
    this.formatImages();
  }

  @Watch('inspection')
  @Watch('formatter')
  private inspectionFormatterWatcher() {
    this.formatImages();
  }

  private formatImages() {
    this.images = [];

    if (this.formatter && this.formatter.images) {
      this.formatter.images.forEach((pv: any) => {
        if (pv && pv.images) {
          pv.images.forEach((i: any) => {
            if (i) {
              this.images.push({
                ...i,
                isVideo: i.thumb_url === null,
              });
            }
          });
        }
      });
    } else if (this.inspection.parameterValues) {
      this.inspection.parameterValues.forEach((pv: any) => {
        if (pv && pv.images) {
          pv.images.forEach((i: any) => {
            if (i) {
              this.images.push({
                ...i,
                filename: pv.parameter!.name,
                name: pv.parameter!.name,
                isVideo: i.thumb_url === null,
              });
            }
          });
        }
      });
    }

    ((this.inspection! as Inspection_inspection).images || []).forEach((i: any) => {
      if (i && this.images.findIndex((im) => im.id === i.id) < 0) {
        this.images.push({
          ...i,
          isVideo: i && i.thumb_url === null,
        } as ImageViewerImage);
      }
    });
  }

  private beforeDestroy() {
    if (this.keyPressListener) {
      document.removeEventListener('keydown', this.keyPressListener);
    }
  }

  /**
   * Set the selected (image) index
   */
  private select(index: number) {
    // Revert scale and rotation
    this.rotation = 0;
    this.scale = 1;
    this.translateX = 0;
    this.translateY = 0;

    // Change index
    this.index = index;
  }

  /**
   * Rotate image left (anti-clockwise)
   */
  private rotateLeft() {
    this.rotation -= 90;
  }

  /**
   * Rotate image right (clockwise)
   */
  private rotateRight() {
    this.rotation += 90;
  }

  /**
   * Zoom image in
   */
  private zoomIn() {
    if (this.scale <= 2.5) {
      this.scale += 0.5;
    }
  }

  private get hasPiPCheck() {
    if (!this.image.checks) {
      return false;
    }

    return this.image.checks
      .filter((check: any) => check.namespace === 'PictureInPicture')
      .filter((check: any) => check.level === 'ERROR' || check.level === 'WARNING')
      .length > 0;
  }

  private get anomalyNoFeedbackGiven() {
    if (!this.image.checks) {
      return true;
    }

    if (this.userGaveAnomalyFeedback) {
      return false;
    }

    return this.image.checks
      .filter((check: any) => check.namespace === 'PictureInPicture')
      .filter((check: any) => check.message === 'MANUALLY_CHECKED')
      .length === 0;
  }

  private getPiPCheckID() {
    return this.image.checks
      .filter((check: any) => check.namespace === 'PictureInPicture')
      .filter((check: any) => check.level === 'ERROR' || check.level === 'WARNING')
      .map((a: any) => a.id);
  }

  private acceptInspectionCheckImage() {
    this.mutateInspectionImageAnomalyCheck('yes');
  }

  private denyInspectionCheckImage() {
    this.mutateInspectionImageAnomalyCheck('no');
  }

  private async mutateInspectionImageAnomalyCheck(questionAnswer: any) {
    await this.$apollo.mutate({
      mutation: require('@/graphql/mutations/PictureInPictureChecked.graphql'),
      variables: {
        id: this.getPiPCheckID()[0],
        question: this.pictureInPictureAnomalyQuestion,
        question_answer: questionAnswer,
      },
    }).then((success) => {
      if (!success) {
        this.error = (this.$it('global.error', 'Something went wrong, please try again') as string);
      } else {
        this.userGaveAnomalyFeedback = true;
      }
    }).catch((error) => {
      this.error = error.message;
    });
  }

  private zoomOut() {
    if (this.scale > 0.5) {
      this.scale -= 0.5;
    }
  }

  private imageMouseDown(event: MouseEvent) {
    const resource = this.$refs.resource as HTMLElement;
    if (resource) {
      this.originX = event.pageX - this.translateX;
      this.originY = event.pageY - this.translateY;
    }
  }

  private imageMouseMove(event: MouseEvent) {
    event.preventDefault();
    if (this.originX && this.originY) {
      this.translateX = event.pageX - this.originX;
      this.translateY = event.pageY - this.originY;
    }
  }

  private imageMouseUp() {
    this.originX = null;
    this.originY = null;
  }

  private getPredictionDataForImage(id: any) {
    if (this.gripProxy) {
      const data = this.gripProxy.getDataForImage(id);
      if (data && data.predictions.length > 0) {
        this.predictionData = data.predictions;
        this.sortPredictionData();
      }
    }
  }

  private sortPredictionData() {
    this.predictionData.sort((a, b) => {
      if (b.value === '1') {
        return -1;
      }
      return 0;
    }).reverse();
  }

  @Watch('index')
  private imageIndexChanged() {
    this.getPredictionDataForImage(this.images[this.index].id);
  }
}
