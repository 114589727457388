



































































































































































import {Component, Vue} from 'vue-property-decorator';
import {
  Account, Account_account, ExecuteInspectionVariables,
  Inspection_inspection,
  Inspection_inspection_flow_parameterCategories,
  Inspection_inspection_flow_parameterCategories_parameterGroups,
  Inspection_inspection_flow_parameterCategories_parameterGroups_parameters,
  InspectionParameterValueInput,
  InspectionStatus,
} from '@/types/intrador';
import InspectionFormatter from '@/plugins/inspection/InspectionFormatter';
import ActionBar from '@/layouts/back-office/elements/ActionBar.vue';
import ActionButton from '@/layouts/back-office/elements/actions/ActionButton.vue';
import Card from '@/layouts/back-office/elements/Card.vue';
import Detail from '@/layouts/back-office/main/Detail.vue';
import InspectionInput from '@/components/inspection/edit/InspectionInput.vue';
import InspectionInputImage from '@/components/inspection/edit/InspectionInputImage.vue';
import ImageViewer from '@/components/ImageViewer.vue';
import Modal from '@/layouts/back-office/elements/modals/Modal.vue';
import InspectionModalImages from '@/components/inspection/edit/InspectionModalImages.vue';
import InspectionModalRemarks from '@/components/inspection/edit/InspectionModalRemarks.vue';
import SpinnerButton from '@/layouts/back-office/elements/SpinnerButton.vue';
import InputSelect from '@/layouts/back-office/elements/input/InputSelect.vue';
import InputInspector from '@/components/elements/input/InputInspector.vue';
import InputDate from '@/layouts/back-office/elements/input/InputDate.vue';
import InputTextarea from '@/layouts/back-office/elements/input/InputTextarea.vue';
import InputMake from '@/layouts/back-office/elements/input/InputMake.vue';
import InputAssetGroup from '@/layouts/back-office/elements/input/InputAssetGroup.vue';
import InputType from '@/layouts/back-office/elements/input/InputType.vue';
import InputText from '@/layouts/back-office/elements/input/InputText.vue';
import InputAddress from '@/layouts/back-office/elements/input/InputAddress.vue';
import InputContact from '@/layouts/back-office/elements/input/InputContact.vue';
import BadgeWrapper from '@/layouts/back-office/elements/BadgeWrapper.vue';
import {InspectionFormatterImage} from '@/plugins/inspection/InspectionFormatterImageGroup';

@Component({
  components: {
    BadgeWrapper,
    InputContact,
    InputAddress,
    InputText,
    InputType,
    InputAssetGroup,
    InputMake,
    InputTextarea,
    InputDate,
    InputInspector,
    InputSelect,
    SpinnerButton,
    InspectionModalRemarks,
    InspectionModalImages,
    Modal,
    ImageViewer,
    InspectionInputImage,
    InspectionInput,
    ActionBar,
    ActionButton,
    Card,
    Detail,
  },
})
export default class InspectionsEdit extends Vue {
  private formatter = new InspectionFormatter();

  get inspectionTypes() {
    const finalInspection = this.$it('inspection.inspection-type.final-inspection', 'Final inspection') as string;

    return {[finalInspection]: 2};
  }

  private queryError: string | null = null;
  private error: string | null = null;

  private inspectionType: string | null = null;
  private inspector: string | null = null;
  private plannedAt: Date | null = null;
  private dueAt: Date | null = null;
  private remark: any = null;

  private group: string | null = null;
  private make: string | null = null;
  private type: string | null = null;
  private year: number | null = null;
  private serialNumber: string | null = null;
  private licensePlate: string | null = null;

  private address: any = null;
  private contact: any = null;

  private externalId: string| null = null;

  private submittedAt: Date | null = null;

  private get isSubmitted(): boolean {
    return this.formatter.inspection ? this.formatter.inspection.status === InspectionStatus.SUBMITTED : false;
  }

  private get isAccepted(): boolean {
    return this.formatter.inspection ? this.formatter.inspection.status === InspectionStatus.ACCEPTED : false;
  }

  private get userIsInspector(): boolean {
    // Get user from cache
    const data = this.$apollo.getClient().store.getCache().read<Account>({
      query: require('@/graphql/Account.gql'),
      optimistic: true,
    });

    // Check if we have a data and formatter
    if (!data || !this.formatter.inspection) {
      return false;
    }

    // Is the current user the inspector of the inspection?
    return data.account?.id === this.formatter.inspection.inspector?.id;
  }

  private get canEdit(): boolean {
    if (!this.formatter.inspection) {
      return false;
    }

    // If I am the inspector, I should be able to edit, else we should check the status
    if (!this.userIsInspector && !(this.isAccepted || this.isSubmitted)) {
      this.error = this.$it('inspection.edit.error.needs-to-be-submitted', 'You cannot edit this inspection until it is submitted') as string;

      return false;
    }

    if (this.formatter.inspection.inspectionType) {
      if (this.formatter.inspection.inspectionType.id === '3') {
        return this.$can('inspections-edit');
      } else {
        return this.$can('inspections-edit');
      }
    }

    return false;
  }

  private get variables() {
    const data = {
      id: this.formatter.inspection!.id,
      images: [],
      overwrite: false,
      parameterValues: Object.values(this.formatter.inspectionValues)
        .filter((pv) => !pv!.placeholder)
        .map((pv) => {
          const d = {
            id: pv!.id,
            value: pv!.value,
            remarks: pv!.remarks!.map((r) => ({id: r!.id, message: r!.message})),
            images: pv!.images!.map((i) => i!.id),
            parameter: {
              id: pv!.parameter!.id,
            },
            parameterGroup: null,
            createdAt: pv!.createdAt,
          } as InspectionParameterValueInput;

          if (pv!.parameterGroup && pv!.parameterGroup.id) {
            d.parameterGroup = {
              id: pv!.parameterGroup.id,
            };
          }

          return d;
        }),
    } as ExecuteInspectionVariables;

    if (this.submittedAt) {
      data.submittedAt = this.submittedAt.toISOString();
    }

    return data;
  }

  private value(parameterCategory: Inspection_inspection_flow_parameterCategories,
                parameterGroup: Inspection_inspection_flow_parameterCategories_parameterGroups,
                parameter: Inspection_inspection_flow_parameterCategories_parameterGroups_parameters) {
    return this.formatter.inspectionValues[`${parameterCategory.parameterCategoryPcid}-${parameterGroup.parameterGroupPgid || 0}-${parameter.parameterPid}`];
  }

  private modalImages(parameterCategory: Inspection_inspection_flow_parameterCategories,
                      parameterGroup: Inspection_inspection_flow_parameterCategories_parameterGroups,
                      parameter: Inspection_inspection_flow_parameterCategories_parameterGroups_parameters) {
    (this.$refs.modalImages as InspectionModalImages).open(
      this.formatter.inspection!.id,
      this.value(parameterCategory, parameterGroup, parameter),
    );
  }

  private modalRemark(parameterCategory: Inspection_inspection_flow_parameterCategories,
                      parameterGroup: Inspection_inspection_flow_parameterCategories_parameterGroups,
                      parameter: Inspection_inspection_flow_parameterCategories_parameterGroups_parameters,
                      requireInput: boolean = false) {
    (this.$refs.modalRemarks as InspectionModalRemarks).open(
      this.value(parameterCategory, parameterGroup, parameter),
      requireInput,
    );
  }

  private getData(data: Inspection_inspection) {
    if (data && this.formatter.inspection === null) {
      this.formatter.inspection = data;

      this.inspectionType = (data.inspectionType) ? data.inspectionType.id : null;
      this.inspector = (data.inspector) ? data.inspector.id : null;
      this.plannedAt = (data.plannedAt) ? new Date(data.plannedAt) : null;
      this.dueAt = (data.dueAt) ? new Date(data.dueAt) : null;
      this.remark = data.remark;

      this.address = this.$inspection.resolveAddressInput(data.address);
      this.contact = this.$inspection.resolveContactInput(data.contact);
      this.externalId = data.externalId;

      if (data.asset) {
        this.group = (data.asset.group) ? data.asset.group.id : null;
        if (data.asset.makeModel) {
          this.make = (data.asset.makeModel.make) ? data.asset.makeModel.make.name : null;
          this.type = data.asset.makeModel.name;
        }
        this.year = data.asset.yearOfManufacture;
        this.serialNumber = data.asset.serialNumber;
        this.licensePlate = data.asset.licensePlate;
      }
    }
  }

  private uploadImage(image: any) {
    if (this.$refs.viewer) {
      (this.$refs.viewer as ImageViewer).addImage(image);
    }
  }

  private openImage(id: string) {
    if (this.$refs.viewer) {
      (this.$refs.viewer as ImageViewer).open(id);
    }
  }

  private async removeDeletedImages(deletedImages: InspectionFormatterImage[]) {
    return new Promise((resolve: () => void, reject: () => void) => {
      if (deletedImages.length === 0) {
        return resolve();
      }

      let done = 0;
      let rejected = false;
      deletedImages.forEach(async (image) => {
        try {
          const result = await this.$apollo.mutate({
            mutation: require('@/graphql/mutations/RemoveImage.gql'),
            variables: {id: image.id},
          });
        } catch (e) {
          rejected = true;
          reject();
          if (e.graphQLErrors) {
            this.queryError = e.graphQLErrors.map((e: any) => e.message).join(', ');
          } else {
            this.queryError = e.message;
          }
        }

        if (!rejected && deletedImages.length >= ++done) {
          resolve();
        }
      });
    });
  }

  private submit(spinning: (state?: boolean) => boolean) {
    // Submit the inspection by setting the submittedAt date
    this.submittedAt = new Date();

    // Save as usual
    return this.save(spinning);
  }

  private async save(spinning: (state?: boolean) => boolean) {
    this.queryError = null;

    spinning();

    await this.removeDeletedImages(Object.values(this.formatter.inspectionValues).flatMap((group) => {
      const formatImageGroup = (group.images?.filter((i) => i)) as InspectionFormatterImage[];
      return formatImageGroup.filter((image: InspectionFormatterImage) => image.deletedAt !== null);
    }));

    let resultParameters: any = true;

    if (this.canEdit) {
      try {
        resultParameters = await this.$apollo.mutate({
          mutation: require('@/graphql/mutations/ExecuteInspection.gql'),
          variables: this.variables,
          refetchQueries: [
            {
              query: require('@/graphql/queries/inspection/Inspection.gql'),
              variables: {
                id: this.variables.id,
                displayType: 'report',
                entityOnly: true,
              },
            },
          ],
          awaitRefetchQueries: true,
        });
      } catch (e) {
        if (e.graphQLErrors) {
          this.queryError = e.graphQLErrors.map((e: any) => e.message).join(', ');
        } else {
          this.queryError = e.message;
        }
      }
    }

    let resultInspection: any = true;
    if (resultParameters && this.canEdit) {
      const inspection = this.$inspection.generateInput({
        inspectionType: this.inspectionType,
        inspector: this.inspector,
        plannedAt: this.plannedAt,
        dueAt: this.dueAt,
        remark: this.remark,

        group: this.group,
        make: this.make,
        type: this.type,
        year: this.year,
        serialNumber: this.serialNumber,
        licensePlate: this.licensePlate,

        address: this.address,
        contact: this.contact,

        externalId: this.externalId,
      });

      try {
        resultInspection = await this.$apollo.mutate({
          mutation: require('@/graphql/mutations/UpdateInspection.gql'),
          variables: {
            id: this.formatter.inspection!.id,
            inspection,
          },
        });
      } catch (e) {
        if (e.graphQLErrors) {
          this.queryError = e.graphQLErrors.map((e: any) => e.message).join(', ');
        } else {
          this.queryError = e.message;
        }
      }
    }

    spinning(false);

    if (resultParameters && resultInspection) {
      this.$router.push({name: 'inspections-detail', params: {id: this.formatter.inspection!.id}});
    }
  }

  private async cancel(spinning: (state?: boolean) => boolean) {
    await this.removeDeletedImages(Object.values(this.formatter.inspectionValues).flatMap((group) => {
      const formatImageGroup = (group.images || []) as InspectionFormatterImage[];
      return formatImageGroup.filter((image: InspectionFormatterImage) => image.recentlyCreated);
    }));

    spinning();

    this.$router.back();

    spinning(false);
  }

  private paramChanged(value: any) {
    // pass
  }
}
