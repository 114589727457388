








import {Component, Prop, Vue} from 'vue-property-decorator';
import Card from './Card.vue';

@Component({
  components: {Card},
})
export default class ActionBar extends Vue {
}
